export default `<ol><li><h3>ABOUT OUR TERMS</h3><ol><li>These terms and conditions of use ("<strong>Terms</strong>") explain how you may use this website and any of its
content ("<strong>Site</strong>"). These Terms apply between Project Management High Intensity Training Ltd
(pmhit) ("<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>") and you, the person accessing or using the Site ("<strong>you</strong>" or "<strong>your</strong>").</li><li>You should read these Terms carefully before using the Site. By using the Site or otherwise indicating your consent, you agree to be bound by these Terms. If you do not agree with any of these Terms, you should stop using the Site immediately.</li><li>These Terms apply to any parts of the Site, its functionality and content provided to you free of charge for information purposes only.</li><li>If you order any services or digital content from the Site, separate terms and conditions will apply.</li></ol></li><li><h3>ABOUT US</h3><ol><li>We are Project Management High Intensity Training Limited (trading as pmhit), a company
registered in England and Wales under company registration number 12789365. Our registered
office is at 68 Arlington Road, Teddington, TW11 8NJ, UK.</li><li><p>If you have any questions about the Site, please contact us by:</p><ol><li>sending an email to info@pmhit.com</li><li>filling out and submitting the online form available <a href="https://pmhit.com/contact">here</a>, or</li><li>calling us on +44 (0)20 30 26 27 05 (our telephone lines are open Monday to Friday: 9
am to 5 pm).</li></ol></li></ol></li><li><h3>USING THIS SITE</h3><ol><li>The Site is for your use only.</li><li>You agree that you are solely responsible for all costs and expenses you may incur in relation to
your use of the Site.</li><li>We make no promise that the Site is appropriate or available for use in locations outside of the UK.
If you choose to access the Site from locations outside the UK, you acknowledge you do so at your
own initiative and are responsible for compliance with local laws where they apply.</li><li>We try to make the Site as accessible as possible. If you have any difficulties using the Site, please
contact us using the contact details at the top of this page.</li></ol></li><li><h3>UNACCEPTABLE USE</h3><ol><li><p>As a condition of your use of the Site, you agree not to use the Site:</p><ol><li>for any purpose that is unlawful under any applicable law or prohibited by these Terms or
any other Terms or Policies made available on this website;</li><li>to commit any act of fraud;</li><li>to distribute viruses or malware or other similar harmful software code;</li><li>for purposes of promoting unsolicited advertising or sending spam;</li><li>to simulate communications from us or another service or entity in order to collect identity
information, authentication credentials, or other information (‘phishing’);</li><li>in any manner that disrupts the operation of our Site or business or the website or
business of any other entity;</li><li>in any manner that harms minors;</li><li>to promote any unlawful activity;</li><li>to represent or suggest that we endorse any other business, product or service unless we
have separately agreed to do so in writing;</li><li>to gain unauthorised access to or use of computers, data, systems, accounts or
networks; or</li><li>to attempt to circumvent password or user authentication methods.</li></ol></li><li><p>As a condition of your use of the Site, you further agree to not to:</p><ol><li>misuse or attack our Site by knowingly introducing viruses, trojans, worms, logic bombs
or any other material which is malicious or technologically harmful (such as by way of a
denial-of-service attack), or</li><li>attempt to gain unauthorised access to our Site, the server on which our Site is stored or
any server, computer or database connected to our Site.</li></ol></li><li><p>We may prevent or suspend your access to the Site if you do not comply with these Terms or any
applicable law.</p></li></ol></li><li><h3>REGISTRATION AND PASSWORD SECURITY</h3><ol><li>Use of the Site may require registration, particularly in order to access restricted areas of the Site.</li><li>We are not obliged to permit anyone to register with the Site and we may refuse, terminate or
suspend registration to anyone at any time.</li><li>You are responsible for making sure that your password and any other account details are kept
secure and confidential.</li><li>If we have reason to believe there is likely to be a breach of security or misuse of the Site through
your account or the use of your password, we may notify you and require you to change your
password, or we may suspend or terminate your account.</li><li>Any personal information you provide to us as part of the registration process will be processed in
accordance with our "<strong>Privacy Policy</strong>" available at <a href="https://pmhit.com/privacy">https://pmhit.com/privacy</a>.</li></ol></li><li><h3>LINKING AND FRAMING</h3><ol><li><p>You may create a link to our Site from another website without our prior written consent provided
no such link:</p><ol><li>creates a frame or any other browser or border environment around the content of our
Site;</li><li>implies that we endorse your products or services or any of the products or services of, or
available through, the website on which you place a link to our Site;</li><li>displays any of the trade marks or logos used on our Site without our permission or that
of the owner of such trade marks or logos; or</li><li>is placed on a website that itself breaches this Policy.</li><li>We reserve the right to require you to immediately remove any link to the Site at any time,
and you shall immediately comply with any request by us to remove any such link.</li></ol></li></ol></li><li><h3>USING OUR NAME AND LOGO</h3><ol><li>You may not use our trade marks, logos or trade names except with our prior written consent.</li></ol></li><li><h3>BREACH</h3><ol><li>We shall apply these Terms in our absolute discretion. In the event of your breach of these Terms
we may terminate or suspend your use of the Site, or disclose any other communication to users of
our Site by you to law enforcement authorities or take any action we consider necessary to remedy
the breach.</li></ol></li><li><h3>INFRINGING CONTENT</h3><ol><li><p>We will use reasonable efforts to:</p><ol><li>delete accounts which are being used in an inappropriate manner or in breach of these
Terms; and</li><li>identify and remove any content that is inappropriate, defamatory, infringes intellectual
property rights or is otherwise in breach of our Acceptable Use Policy when we are notified, but we cannot be responsible if you have failed to provide us with the relevant information.</li></ol></li><li><p>If you believe that any content which is distributed or published by the Site is inappropriate,
defamatory or infringing on intellectual property rights, you should contact us immediately using the
contact details at the top of this page.</p></li></ol></li><li><h3>YOUR PRIVACY AND PERSONAL INFORMATION</h3><ol><li>Your privacy and personal information are important to us. Any personal information that you
provide to us will be dealt with in line with our Privacy Policy available at <a href="https://pmhit.com/privacy">https://pmhit.com/privacy</a>, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and
supervisory authorities in the event you have a query or complaint about the use of your personal
information.</li></ol></li><li><h3>OWNERSHIP, USE AND INTELLECTUAL PROPERTY RIGHTS</h3><ol><li>The intellectual property rights in the Site and in any text, images, video, audio or other multimedia
content, software or other information or material submitted to or accessible from the Site
("<strong>Content</strong>") are owned by us and our licensors.</li><li>We and our licensors reserve all our intellectual property rights (including, but not limited to, all
copyright, trade marks, domain names, design rights, database rights, patents and all other
intellectual property rights of any kind) whether registered or unregistered anywhere in the world.
This means, for example, that we remain owners of them and are free to use them as we see fit.</li><li>Nothing in these Terms grants you any legal rights in the Site or the Content other than as
necessary for you to access it. You agree not to adjust, try to circumvent or delete any notices
contained on the Site or the Content (including any intellectual property notices) and in particular, in
any digital rights or other security technology embedded or contained within the Site or the
Content.</li><li>Trade marks including but not limited to our logo and the name pmhit: these are our trademarks.
Other trade marks and trade names may also be used on the Site or in the Content. Use by you of any trade marks on the Site or in the Content is strictly prohibited unless you have our prior written
permission.</li></ol></li><li><h3>SUBMITTING INFORMATION TO THE SITE</h3><ol><li>While we try to make sure that the Site is secure, we do not actively monitor or check whether
information supplied to us through the Site is confidential, commercially sensitive or valuable.</li><li>Other than any personal information which will be dealt with in accordance with our Privacy Policy,
we do not guarantee that information supplied to us through the Site will be kept confidential and
we may use it on an unrestricted and free-of-charge basis as we reasonably see fit.</li></ol></li><li><h3>ACCURACY OF INFORMATION AND AVAILABILITY OF THE SITE</h3><ol><li>We try to make sure that the Site is accurate, up-to-date and free from bugs, but we cannot
promise that it will be. Furthermore, we cannot promise that the Site will be fit or suitable for any
purpose. Any reliance that you may place on the information on the Site is at your own risk.</li><li>We may suspend or terminate access or operation of the Site at any time as we see fit.</li><li>Any Content is provided for your general information purposes only and to inform you about us and
our products and news, features, services and other websites that may be of interest, but has not
been tailored to your specific requirements or circumstances. It does not constitute technical,
financial or legal advice or any other type of advice and should not be relied on for any purposes.
You should always use your own independent judgment when using our Site and its Content.</li><li>While we try to make sure that the Site is available for your use, we do not promise that the Site will
be available at all times or that your use of the Site will be uninterrupted.</li></ol></li><li><h3>HYPERLINKS AND THIRD PARTY SITES</h3><p>The Site may contain hyperlinks or references to third party advertising and websites other than the
Site. Any such hyperlinks or references are provided for your convenience only. We have no
control over third party advertising or websites and accept no legal responsibility for any content,
material or information contained in them. The display of any hyperlink and reference to any third
party advertising or website does not mean that we endorse that third party's website, products or
services. Your use of a third party site may be governed by the terms and conditions of that third-
party site and is at your own risk.</p></li><li><h3>LIMITATION ON OUR LIABILITY</h3><ol><li><p>Except for any legal responsibility that we cannot exclude in law (such as for death or personal
injury) or arising under applicable laws relating to the protection of your personal information, we
are not legally responsible for any:</p><ol><li>losses that were not foreseeable to you and us when these Terms were formed;</li><li>losses that were not caused by any breach on our part;</li><li>business losses; and</li><li>losses to non-consumers.</li></ol></li></ol></li><li><h3>EVENTS BEYOND OUR CONTROL</h3><p>We are not liable to you if we fail to comply with these Terms because of circumstances beyond
our reasonable control, including, but not limited to, strikes, lock-outs or other industrial disputes;
breakdown of systems or network access; or flood, fire, explosion or accident.</p></li><li><h3>RIGHTS OF THIRD PARTIES</h3><p>No one other than a party to these Terms has any right to enforce any of these Terms.</p></li><li><h3>VARIATION</h3><ol><li>No changes to these Terms are valid or have any effect unless agreed by us in writing or made in
accordance with this clause 18</li><li>We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed
on the Site and by continuing to use and access the Site following such changes, you agree to be
bound by any variation made by us. It is your responsibility to check these Terms from time to time
to verify such variations.</li></ol></li><li><h3>DISPUTES</h3><ol><li>These Terms are governed by and construed in all respects in accordance with the laws of
England and Wales.</li><li>We will try to resolve any disputes with you quickly and efficiently. Any or all disputes arising
between you and us (whether contractual or non-contractual) in connection with your access to and
use of the Training, including as to the validity of these Terms or policy issued by us, will be subject
to the exclusive jurisdiction of the courts of England and Wales. If you are unhappy with us, you
agree that you will first attempt to resolve it with us using the contact details set out at the top of
this page. In the unlikely event that we are unable to resolve the dispute in this manner within 28
days of the dispute being notified to us in writing, we both agree to submit to the exclusive
jurisdiction of the courts of England and Wales to resolve any such dispute.</li></ol></li></ol>`;
