export default `<p>Please read this cookie policy carefully as it contains important information on who we are and how we use
cookies on our website. This policy should be read together with our <a href="https://pmhit.com/privacy">Privacy Policy</a> which sets
out how and why we collect, store, use and share personal information generally, as well as your rights in
relation to your personal information and details of how to contact us and supervisory authorities if you have
a complaint.</p><h3>Who we are</h3><p>This website is operated by Netlify, Inc., located at 2325 3rd Street, Suite 296, San Francisco, California. We are Project Management High Intensity Training Limited, a company registered in England &amp; Wales under company registration number 12789365</p><h3>Our website</h3><p>This cookie policy only relates to your use of our website, www.pmhit.com</p><p>Throughout our website we may link to other websites owned and operated by certain trusted third parties to
make additional products and services available to you. These other third party websites may also use
cookies or similar technologies in accordance with their own separate policies. For privacy information
relating to these other third party websites, please consult their policies as appropriate.</p><h3>Cookies</h3><p>A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic
device) when you use our website. We use cookies on our website. These help us recognise you and your
device and store some information about your preferences or past action.</p><p>For example, we may monitor how many times you visit the website, which pages you go to, traffic data,
location data and the originating domain name of your internet service provider. This information helps us to
build a profile of our users.</p><p>For further information on cookies generally, including how to control and manage them, visit the guidance
on cookies published by the UK Information Commissioner’s Office, <a href="www.aboutcookies.org">www.aboutcookies.org</a> or
<a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p><h3>Consent to use cookies</h3><p>By continuing to browse the site, you are agreeing to our use of cookies.</p><h3>How to turn off all cookies and consequences of doing so</h3><p>If you do not want to accept any cookies, you may be able to change your browser settings so that cookies (including those which are essential to the services requested on our site) are not accepted. If you do this, please be aware that you may lose some of the functionality of our website.</p><p>For further information about cookies and how to disable them please go to the guidance on cookies published by the UK Information Commissioner’s Office, www.aboutcookies.org or www.allaboutcookies.org.</p><h3>How to contact us</h3><p>Please contact us if you have any questions about this cookie policy.</p><p>If you wish to contact us, please send an email to <a href="mailto:info@pmhit.com">info@pmhit.com</a> or call +44 (0) 20 30 26 27 05.</p><h3>Changes to this policy</h3><p>This policy was published on 1<sup>st</sup> October 2020.</p><p>We may change this policy from time to time, when we do the updated policy will be available via our
website.</p>`;
