import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import ContactForm from '../ContactForm';

import SocialIcons from '../SocialIcons';
import Logo from '../../assets/logo_blue.inline.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    color: theme.palette.primary.light,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(36),
      marginBottom: '-24px',
    },
  },
  infoTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginRight: '10px',
      flexDirection: 'row',
      alignItems: 'center',
    },
    marginBottom: theme.spacing(2),
  },
  infoTitle: {
    fontFamily: 'Montserrat',
    [theme.breakpoints.up('md')]: {
      marginRight: '20px',
    },
  },
  logo: {
    marginLeft: '-10px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '200px',
    },
  },
}));

const ContactFormBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={12} sm="auto">
            <Typography
              variant="h3"
              component="h1"
              align="center"
              className={classes.subTitle}
            >
              Got a Question?
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="h4" align="center" className={classes.title}>
              We're here to help
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContactForm />
          </Grid>
          <Grid item xs={12}>
            <Box component={Paper} p={2}>
              <div className={classes.infoTitleWrapper}>
                <Logo className={classes.logo} />
                <Typography variant="h5" color="primary" className={classes.infoTitle}>
                  Project Management High Intensity Training Ltd.
                </Typography>
              </div>
              <Divider />
              <Box display="flex" mt={1}>
                <SocialIcons display="flex" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactFormBanner;
