import React from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
}));

const TextCard = ({ title, text, center }) => {
  const classes = useStyles();
  return (
    <Paper component={Box} p={2}>
      <Typography
        variant="h5"
        className={classes.title}
        align={center ? 'center' : 'inherit'}
      >
        {title}
      </Typography>
      <Typography variant="body1" align={center ? 'center' : 'inherit'}>
        {text}
      </Typography>
    </Paper>
  );
};

export default TextCard;
