import { Box, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { Email, LinkedIn, Twitter, Phone } from '@material-ui/icons';
import React from 'react';

const buttonStyle = {
  marginLeft: '4px',
  marginRight: '4px',
};

const buttonStyle2 = {
  marginRight: '4px',
};

const SocialIcons = (props) => {
  const color = props.color;
  const isFooter = props.isFooter;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {typeof isFooter === 'undefined' ? (
        <Box {...props}>
          <IconButton href="tel:+442030262705" style={buttonStyle}>
            <Phone style={{ color: `${color}` }} />
          </IconButton>
          <IconButton href="mailto:info@pmhit.com" style={buttonStyle}>
            <Email style={{ color: `${color}` }} />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/pmhit/" style={buttonStyle}>
            <LinkedIn style={{ color: `${color}` }} />
          </IconButton>
          <IconButton href="https://twitter.com/ProjectHIT" style={buttonStyle}>
            <Twitter style={{ color: `${color}` }} />
          </IconButton>
        </Box>
      ) : (
        <Grid container alignItems="center" justify="space-around">
          <Grid item xs={6} sm={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={isSmall ? 'center' : 'flex-start'}
            >
              <IconButton href="mailto:info@pmhit.com" style={buttonStyle2}>
                <Email style={{ color: `${color}` }} />
              </IconButton>
              <div style={{ color: `${color}` }}>info@pmhit.com</div>
            </Box>
          </Grid>
          <Grid item xs={6} sm={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={isSmall ? 'center' : 'flex-start'}
            >
              <IconButton href="tel:+442030262705" style={buttonStyle2}>
                <Phone style={{ color: `${color}` }} />
              </IconButton>
              <div style={{ color: `${color}`, textTransform: 'none' }}>
                +44 (0) 20 30 26 27 05
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SocialIcons;
