import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import SocialIcons from '../SocialIcons';

import Logo from '../../assets/logo.inline.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  logo: {
    maxWidth: '200px',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: '-8px',
    // },
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  items: {
    paddingBottom: theme.spacing(2),
  },
  item: {
    color: grey[200],
    justifyContent: 'flex-start',
  },
  socialIcons: {
    color: 'white',
  },
  branding: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  copyright: {
    backgroundColor: theme.palette.primary.dark,
    color: grey[200],
    padding: theme.spacing(1),
    letterSpacing: '1px',
    fontFamily: 'Montserrat',
    width: '100%',
    [theme.breakpoints.down('sm')]: { textAlign: 'center' },
  },
}));

const links = [
  {
    title: 'Links',
    items: [
      {
        title: 'Home',
        path: '/',
      },
      {
        title: 'Training',
        path: '/training',
      },
      {
        title: 'Coaching',
        path: '/coaching',
      },
      {
        title: 'Consulting',
        path: '/consulting',
      },
    ],
  },
  {
    title: 'Company',
    items: [
      {
        title: 'Contact Us',
        path: '/contact',
      },
      {
        title: 'Official Blog',
        path: '/blog',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        title: 'Terms',
        path: '/terms',
      },
      {
        title: 'Privacy',
        path: '/privacy',
      },
      {
        title: 'Cookie Policy',
        path: '/cookie-policy',
      },
    ],
  },
];

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={3} className={classes.items}>
              <Grid
                container
                justify={isSmall ? 'center' : 'flex-start'}
                className={classes.branding}
              >
                <Grid item xs={12}>
                  {/* <Button to="/" className={classes.logo}> */}
                  <Logo className={classes.logo} />
                  {/* </Button> */}
                </Grid>
                <Grid item xs={12}>
                  <SocialIcons isFooter={true} color="white" />
                </Grid>
              </Grid>
            </Grid>
            {links.map((linkGroup) => (
              <Grid key={linkGroup.title} item xs={12} md={3} className={classes.items}>
                <Typography variant="h6" className={classes.title}>
                  {linkGroup.title}
                </Typography>
                <Grid container justify="center">
                  {linkGroup.items.map((item) => (
                    <Grid key={item.title} item xs="auto" md={12}>
                      <Button to={item.path} fullWidth={isSmall} className={classes.item}>
                        {item.title}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      <div className={classes.copyright}>
        <Container maxWidth="lg">
          <Typography variant="caption" className={classes.copyright}>
            &copy; {new Date().getFullYear()}, pmhit. All Rights Reserved.
          </Typography>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
