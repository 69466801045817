import React, { useEffect, useState } from 'react';
import { Container, Fade, Grid, makeStyles } from '@material-ui/core';
import CarouselSlide from './CarouselSlide';
import Arrow from './Arrow';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  arrows: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Carousel = () => {
  const queryData = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "quote" } } }) {
        edges {
          node {
            frontmatter {
              quote
              role
              company
            }
          }
        }
      }
    }
  `);
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [data] = useState(() => {
    let quotes = [];
    queryData.allMarkdownRemark.edges.forEach(({ node }) => {
      quotes.push(node.frontmatter);
    });
    return quotes;
  });

  const onArrowClick = (direction) => {
    const increment = direction === 'left' ? -1 : 1;
    const newIndex = (index + increment + data.length) % data.length;
    setFadeIn(false);
    setTimeout(() => {
      setIndex(newIndex);
      setFadeIn(true);
    }, 500);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      onArrowClick('right');
    }, 6000);
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container justify="center" align="center">
          <Grid item xs={12}>
            <Fade in={fadeIn} timeout={500} appear>
              <div>
                <CarouselSlide data={data[index]} />
              </div>
            </Fade>
          </Grid>
          <Grid item xs>
            <div className={classes.arrows}>
              <Arrow direction="left" onClick={() => onArrowClick('left')} />
              <Arrow direction="right" onClick={() => onArrowClick('right')} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Carousel;
