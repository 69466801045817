import React from 'react';
import { Grid, Container, makeStyles, Paper, Typography, Box } from '@material-ui/core';

import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  image: {
    padding: theme.spacing(1),
    height: '100%',
    width: '80%',
    margin: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '100%',
    },
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

const Training = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "training.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} sm={6} md={7}>
            <Paper component={Box} p={2}>
              <Typography variant="h5" className={classes.title}>
                Training that focuses on maximising returns
              </Typography>
              <Typography variant="body1" className={classes.text}>
                Our training courses are designed to give your people the skills they need
                to manage and control their projects. Our training goes beyond text book
                learning, it allows your people to comprehend the complexities of real
                world projects and it gives them the tools to make better decisions under
                pressure.
              </Typography>
              <Typography variant="body1" className={classes.text}>
                We focus on people over processes to get better results.
              </Typography>
              <Typography variant="body1">
                We maximise the returns of your projects by enabling your people to lead
                their projects efficiently, taking decisions that benefit the business
                case, that deliver better results.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Img fluid={data.file.childImageSharp.fluid} className={classes.image} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Training;
