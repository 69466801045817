import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  button: {
    color: grey[100],
  },
}));

const Arrow = ({ direction, onClick }) => {
  const classes = useStyles();
  const Icon = direction === 'left' ? ChevronLeftIcon : ChevronRightIcon;
  return (
    <div className={classes.root}>
      <IconButton onClick={onClick}>
        <Icon className={classes.button} />
      </IconButton>
    </div>
  );
};

export default Arrow;
