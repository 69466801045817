import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Container, Grid, Typography, useMediaQuery } from '@material-ui/core';

import HeroSVG from '../../assets/hero_index.inline.svg';
import { Button } from 'gatsby-theme-material-ui';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { FadingBanner2 } from '../';

const useStyles = makeStyles((theme) => ({
  rootParent: {
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '75vh',
    },
  },
  root: {
    minHeight: '94vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '75vh',
    },
    backgroundColor: '#ffffff',
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ededed' fill-opacity='0.48'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23e7e7e7' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");`,
  },
  bannerRoot: {
    backgroundColor: theme.palette.primary.light,
  },
  grid: {
    minHeight: '94vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '75vh',
    },
    padding: theme.spacing(2),
  },
  taglines: {
    height: '100%',
  },
  tagline: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(42),
    lineHeight: theme.typography.pxToRem(44),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(34),
    },
  },
  subTagline: {
    paddingBottom: theme.spacing(2),
  },
  mediaWrapper: {},
  heroSVG: {
    display: 'block',
    height: 'auto',
    maxHeight: '200px',
    maxWidth: '70%',
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heroIMG: {
    maxHeight: '70vh',
    width: 'auto',
  },
  buttons: {
    margin: theme.spacing(2),
  },
}));

const data = {
  tagline: 'People first solutions for better projects',
  subTagline:
    'We help businesses to maximise the efficiency of their projects. We devise lean processes that work in tandem with your people and your goals. We listen, we never assume. We put your people and your objectives at the heart of the solution.',
};

const Hero = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero_collage_rounded_8.png" }) {
        childImageSharp {
          fluid(maxHeight: 350, quality: 70) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);
  const classes = useStyles();
  const theme = useTheme();
  const isAtleastMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.rootParent}>
      <div className={classes.root}>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item sm={12} md={7}>
              <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                className={classes.taglines}
              >
                <Typography
                  component="h1"
                  align={isAtleastMedium ? 'left' : 'center'}
                  className={classes.tagline}
                >
                  {data.tagline}
                </Typography>
                <Typography
                  variant={isSmall ? 'subtitle1' : 'h6'}
                  align={isAtleastMedium ? 'left' : 'center'}
                  className={classes.subTagline}
                >
                  {data.subTagline}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={isAtleastMedium ? 'flex-start' : 'center'}
                >
                  <Button to="/contact" variant="contained" color="primary">
                    Contact Us
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} md={5}>
              <div className={classes.mediaWrapper}>
                {isAtleastMedium ? (
                  <Img
                    fluid={query.file.childImageSharp.fluid}
                    className={classes.heroIMG}
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <HeroSVG className={classes.heroSVG} />
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.bannerRoot}>
        <FadingBanner2 />
      </div>
    </div>
  );
};

export default Hero;
