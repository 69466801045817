import React from 'react';
import { Typography, makeStyles, Box, Paper, fade } from '@material-ui/core';
import Img from 'gatsby-image';
import { Button, Link } from 'gatsby-theme-material-ui';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardActionArea: {
    padding: 0,
    margin: 0,
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 1,
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: fade(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  cardContent: {},
  date: {
    marginBottom: '4px',
    color: grey[600],
  },
  title: {
    fontFamily: 'Montserrat',
  },
  text: {
    marginTop: theme.spacing(1),
  },
  media: {
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderRadius: '4px 4px 0px 0px',
    overflow: 'hidden',
  },
  dimHover: {
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: fade(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const BlogCard = ({ title, date, image, text, path }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card}>
      <Box component={Link} to={path} underline="none" className={classes.cardActionArea}>
        <Img fluid={image} alt={title} title={title} className={classes.media} />
        <Box p={2} className={classes.cardContent}>
          {date && (
            <Typography variant="subtitle2" className={classes.date}>
              {date}
            </Typography>
          )}
          <Typography variant="h6" color="primary" className={classes.title}>
            {title}
          </Typography>
          {text && (
            <Typography variant="body2" color="textPrimary" className={classes.text}>
              {text}
            </Typography>
          )}
        </Box>
      </Box>
      <Box p={2}>
        <Button
          to={path}
          size="small"
          variant="outlined"
          color="primary"
          style={{
            marginRight: '8px',
          }}
        >
          Read More
        </Button>
      </Box>
    </Paper>
  );
};

export default BlogCard;
