import React from 'react';
import { Box, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import SVG1 from '../../assets/index_2.inline.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
  titleAccordion: {
    fontFamily: 'Montserrat',
  },
  sideText: {
    padding: theme.spacing(1),
  },
  list: {},
  listTitle: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(1),
  },
  listTitle2: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listTitle3: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(2),
  },
  listBody: {
    color: grey[900],
  },
  SVG: {
    display: 'block',
    padding: theme.spacing(1),
    margin: 'auto',
    maxHeight: '250px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '85%',
    },
    objectFit: 'contain',
  },
}));

const listData = [
  {
    id: 1,
    title: 'The management of risk',
    text: [
      'Understanding the project business case and maximising returns',
      'Converting risk into a competitive advantage by controlling ambiguity',
    ],
  },
  {
    id: 2,
    title: 'The management of change',
    text: [
      'Utilising holistic evaluation and critical thinking to make the right decisions',
      'Identifying and capitalising on opportunities to enhance returns',
    ],
  },
  {
    id: 3,
    title: 'The management of stakeholders',
    text: ['Influencing and controlling others to remove obstructions'],
  },
];
const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={7}>
            <Paper component={Box} p={2}>
              <Typography variant="h5" className={classes.title}>
                How we improve project delivery
              </Typography>
              <Box>
                <Typography variant="body1">
                  We believe that the project team are the key to achieving project
                  delivery success. Our approach is to improve the team’s decision making
                  skills and to give each individual the tools to manage and control their
                  environment. We do this by enhancing the project team’s ability in three
                  critical areas.
                </Typography>
                {listData.map((data) => (
                  <Box key={data.id}>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.listTitle2}
                    >
                      {data.title}
                    </Typography>
                    <Typography variant="body1">
                      <ul>
                        {data.text.map((t, index) => (
                          <li key={index}>{t}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <SVG1 className={classes.SVG} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Banner;
