import React from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { BlogCard2 } from '../';
import { graphql, useStaticQuery } from 'gatsby';
import Cards from '../Cards';
import { Button } from 'gatsby-theme-material-ui';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {},
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  titleWrapper: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(430)]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
    color: theme.palette.primary.main,
  },
  middot: {
    visibility: 'hidden',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const BlogBanner = () => {
  const classes = useStyles();
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { featured: { eq: true }, show: { eq: true } } }
          limit: 4
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                author
                image {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 70, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  );
  const length = data.allMarkdownRemark.edges.length;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(430));
  return (
    <>
      {length > 0 && (
        <div className={classes.root}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection={isSmall ? 'column' : 'row'}
              alignItems={isSmall ? 'flex-start' : 'center'}
              mb={2}
            >
              <Typography variant="h4" color="primary" className={classes.title}>
                Featured Article{length > 1 ? 's' : ''}
              </Typography>
              <Button to="/blog" size="small" variant="outlined" color="primary">
                See More
              </Button>
            </Box>
          </Container>
          <Box>
            <Cards>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <Grid
                  item
                  key={node.id}
                  xs={12}
                  md={length === 4 ? 6 : 4}
                  lg={length === 4 ? 3 : 4}
                >
                  <BlogCard2
                    title={node.frontmatter.title}
                    image={node.frontmatter.image.childImageSharp.fluid}
                    path={`/blog${node.fields.slug}`}
                    text={node.excerpt}
                  />
                </Grid>
              ))}
            </Cards>
          </Box>
        </div>
      )}
    </>
  );
};

export default BlogBanner;
