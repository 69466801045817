import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { BlogCardBig } from '..';
import { Button } from 'gatsby-theme-material-ui';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(30),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(390)]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
}));

const Banner = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { type2: { eq: "coaching" } }) {
        frontmatter {
          title
          author
          show
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  `);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(390));
  return (
    <>
      {data.markdownRemark.frontmatter.show ? (
        <div className={classes.root}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection={isSmall ? 'column' : 'row'}
              alignItems={isSmall ? 'flex-start' : 'center'}
              mb={2}
            >
              <Typography variant="h4" color="primary" className={classes.title}>
                Featured Article
              </Typography>
              <Button
                to="/blog/coaching"
                size="small"
                variant="outlined"
                style={{
                  color: 'white',
                  borderColor: 'white',
                }}
              >
                Read More
              </Button>
            </Box>
            <BlogCardBig
              title={data.markdownRemark.frontmatter.title}
              author={data.markdownRemark.frontmatter.author}
              image={data.markdownRemark.frontmatter.image.childImageSharp.fluid}
              html={data.markdownRemark.html}
            />
          </Container>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Banner;
