import React from 'react';
import { Grid, Container, makeStyles, Paper, Typography, Box } from '@material-ui/core';

import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  image: {
    padding: theme.spacing(1),
    height: '100%',
    width: '80%',
    margin: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '100%',
    },
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

const Coaching = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "coaching.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} md={7}>
            <Paper component={Box} p={2}>
              <Typography variant="h5" className={classes.title}>
                Empowering your people to achieve their potential
              </Typography>
              <Typography variant="body1" className={classes.text}>
                We provide coaching that improves your team’s ability to control your
                projects and maximise their returns. We pass on our extensive experience
                of delivering complex projects to your team and demonstrate how to make
                better decisions in volatile and pressurised environments.
              </Typography>
              <Typography variant="body1" className={classes.text}>
                We can support Project Managers and team members who are new into their
                role or established practitioners that need guidance during complex
                execution issues. We quickly increase the project team members’
                capabilities and empower them to be confident with the decision and
                choices they make during the project lifecycle.
              </Typography>
              <Typography variant="body1">
                We give project managers the tools and techniques to take charge of fluid
                situations and to control and influence difficult stakeholders. Coaching
                is centred on your organisation’s specific needs; it is tailored to your
                project and business environment. We listen, we never assume. We put your
                people and your objectives at the heart of the solution.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Img fluid={data.file.childImageSharp.fluid} className={classes.image} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Coaching;
