import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
} from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardActionArea: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
    textAlign: 'left',
    textTransform: 'none',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  closeIcon: {
    color: grey[600],
    boxShadow: `0 0 6px 1px rgba(0, 0, 0, 0.1)`,
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: 'Montserrat',
  },
  titleDialog: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  text: {},
}));

const CourseCard = ({ title, overview, html_content, html_content_show }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Box component={Paper} className={classes.card}>
        <Button
          onClick={() => {
            if (html_content_show) setDialogOpen(true);
          }}
          className={classes.cardActionArea}
        >
          <Box p={2}>
            <Typography variant="h6" color="primary" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.text}>
              {overview}
            </Typography>
          </Box>
        </Button>
        <Box p={2}>
          <Button
            to="/contact"
            size="small"
            variant="contained"
            color="primary"
            style={{
              marginRight: '8px',
            }}
          >
            Contact Sales
          </Button>
          {html_content_show && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setDialogOpen(true)}
            >
              Learn More
            </Button>
          )}
        </Box>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" className={classes.titleDialog}>
              Course Details
            </Typography>
            <IconButton
              onClick={() => setDialogOpen(false)}
              className={classes.closeIcon}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <Typography variant="h5" color="primary" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1">
              {html_content ? (
                <div dangerouslySetInnerHTML={{ __html: html_content }} />
              ) : (
                overview
              )}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CourseCard;
