import React from 'react';
import { Grid, Container, makeStyles, Paper, Typography, Box } from '@material-ui/core';

import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  image: {
    padding: theme.spacing(1),
    height: '100%',
    width: '80%',
    margin: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '100%',
    },
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

const Consulting = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "consulting_new.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} md={7}>
            <Paper component={Box} p={2}>
              <Typography variant="h5" className={classes.title}>
                Enhancing your business via in-depth collaboration
              </Typography>
              <Typography variant="body1" className={classes.text}>
                We specialise in helping business achieve excellence in their projects. We
                do this by truly understanding what your business wants to achieve, then
                plotting a route to success in tandem with your people.
              </Typography>
              <Typography variant="body1" className={classes.text}>
                We understand the complexities of project development and execution. We
                can bring clarity to your project management system and create a lean and
                focused environment that empowers your people to take charge of their
                projects. We create systems and functions that are designed to enable
                success and put your people in full control.
              </Typography>
              <Typography variant="body1" className={classes.text}>
                When we fully understand your business and your concerns we work in
                collaboration with you to deliver the improvements that make the maximum
                impact to your projects results.
              </Typography>
              <Typography variant="body1">
                We create people first solutions for better projects.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Img fluid={data.file.childImageSharp.fluid} className={classes.image} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Consulting;
