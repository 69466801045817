import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Cards = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2} direction="row" justify="center">
          {children}
        </Grid>
      </Container>
    </div>
  );
};

export default Cards;
