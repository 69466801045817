import React from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Box,
} from '@material-ui/core';
import Img from 'gatsby-image';
import { Button } from 'gatsby-theme-material-ui';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  date: {
    marginBottom: '4px',
    color: grey[600],
  },
  title: {
    fontFamily: 'Montserrat',
  },
  text: {
    marginTop: theme.spacing(1),
  },
  actionArea: {
    padding: 0,
    textTransform: 'none',
    position: 'relative',
  },
  media: {
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
}));

const BlogCard = ({ title, date, image, text, path }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardActionArea component={Button} to={path} className={classes.actionArea}>
        <Img fluid={image} alt={title} title={title} className={classes.media} />
        <CardContent>
          {date && (
            <Typography variant="subtitle2" className={classes.date}>
              {date}
            </Typography>
          )}
          <Typography variant="h6" color="primary" className={classes.title}>
            {title}
          </Typography>
          {text && (
            <Typography variant="body2" className={classes.text}>
              {text}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box m={1}>
          <Button
            to={path}
            size="small"
            variant="outlined"
            color="primary"
            style={{
              marginRight: '8px',
            }}
          >
            Read More
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default BlogCard;
