import React from 'react';
import { Grid, Container, makeStyles, Typography } from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { CourseCard2 } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    marginBottom: theme.spacing(2),
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(30),
  },
}));

const CourseGrid = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "course" } } }
        sort: { fields: frontmatter___code }
      ) {
        edges {
          node {
            frontmatter {
              code
              overview
              title
              contentShow
            }
            html
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h4" color="primary" className={classes.title}>
          Our Courses
        </Typography>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          justify="flex-start"
        >
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Grid key={node.frontmatter.code} item xs={12} sm={6} md={4} lg={3}>
              <CourseCard2
                title={node.frontmatter.title}
                overview={node.frontmatter.overview}
                html_content={node.html}
                html_content_show={node.frontmatter.contentShow}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default CourseGrid;
