import React, { useState } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Hidden,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { IconButton, Button } from 'gatsby-theme-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../../assets/logo.inline.svg';

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '200px',
    },
  },
  drawer: {
    width: 'auto',
  },
  drawerWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  drawerHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawerTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  linkButton: {
    color: grey[200],
    opacity: 0.85,
    fontFamily: 'Montserrat',
    textTransform: 'none',
  },
  linkButtonActive: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  closeIcon: {
    color: grey[600],
    boxShadow: `0 0 6px 1px rgba(0, 0, 0, 0.1)`,
  },
}));

const pages = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Training',
    path: '/training',
  },
  {
    title: 'Coaching',
    path: '/coaching',
  },
  {
    title: 'Consulting',
    path: '/consulting',
  },
  // {
  //   title: 'Blog',
  //   path: '/blog',
  // },
  {
    title: 'Contact Us',
    path: '/contact',
  },
];

export default function Navbar({ location, navbarPosition }) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const activePath = location.pathname ? location.pathname : '';

  return (
    <AppBar position={navbarPosition} className={classes.root}>
      <Toolbar>
        <Button to="/" size="large" className={classes.logo}>
          <Logo />
        </Button>
        <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(event) => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <Box>
            {pages.map(({ title, path }) => (
              <Button
                key={path}
                size="large"
                className={
                  activePath === path ? classes.linkButtonActive : classes.linkButton
                }
                to={path}
              >
                {title}
              </Button>
            ))}
          </Box>
        </Hidden>
        <Drawer
          anchor="top"
          open={menuOpen}
          onClose={(event) => setMenuOpen(false)}
          className={classes.drawer}
        >
          <Box className={classes.drawerWrapper}>
            <Box className={classes.drawerHeader}>
              <Typography variant="h4" component="h1" className={classes.drawerTitle}>
                pmhit
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={(event) => setMenuOpen(false)}
                className={classes.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <List>
              {pages.map(({ title, path }) => (
                <ListItem
                  button
                  component={Button}
                  key={path}
                  to={path}
                  style={{ textTransform: 'none' }}
                >
                  <ListItemText primary={title} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
