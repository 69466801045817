import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { Button } from 'gatsby-theme-material-ui';

const Banner = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookies-accepted');
    const cookiesAcceptedExpiry = localStorage.getItem('cookies-accepted-expiry');
    const elapsedSec = Date.now() / 1000 - parseInt(cookiesAcceptedExpiry);
    if (cookiesAccepted) {
      setOpen(elapsedSec > 2592000 ? true : false);
    } else {
      setOpen(true);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    localStorage.setItem('cookies-accepted', true);
    localStorage.setItem('cookies-accepted-expiry', Math.floor(Date.now() / 1000));
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: isMobile ? 'top' : 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      message="This site uses cookies to provide you with a great user experience. By using this site, you accept our use of cookies."
      action={
        <React.Fragment>
          <Button
            to="/cookie-policy"
            variant="outlined"
            size="small"
            onClick={handleClose}
            style={{
              marginRight: '8px',
              color: 'white',
              borderColor: 'white',
            }}
          >
            VIEW COOKIE POLICY
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default Banner;
