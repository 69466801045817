import React from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';

import './github-markdown.css';
import privacyHtml from './privacy';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
}));

const TextCard = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { type: { eq: "privacy" } }) {
        frontmatter {
          title
        }
      }
    }
  `);
  const { markdownRemark } = data;
  return (
    <Paper component={Box} p={2} mt={4} mb={4}>
      <Typography variant="h4" component="h1" className={classes.title}>
        {markdownRemark.frontmatter.title}
      </Typography>
      <Typography variant="body1">
        <Box
          className="markdown-body"
          dangerouslySetInnerHTML={{
            __html: privacyHtml,
          }}
        ></Box>
      </Typography>
    </Paper>
  );
};

export default TextCard;
