import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '20vh',
    padding: theme.spacing(2),
  },
  title: {
    color: 'white',
  },
  quote: {
    color: 'white',
    fontFamily: 'Montserrat',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${grey[100]}`,
  },
}));

const CarouselSlide = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h6" align="left" className={classes.quote}>
        "{data.quote}"
      </Typography>
      <Typography variant="subtitle1" align="left" className={classes.title}>
        {data.role}, {data.company}
      </Typography>
    </div>
  );
};

export default CarouselSlide;
