import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    color: grey[600],
    boxShadow: `0 0 6px 1px rgba(0, 0, 0, 0.1)`,
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: 'Montserrat',
  },
  titleDialog: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  text: {},
  actionArea: {
    padding: 0,
    textTransform: 'none',
  },
  media: {
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
}));

const CourseCard = ({ title, overview, html_content, html_content_show }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Card>
        <CardActionArea
          onClick={() => {
            if (html_content_show) setDialogOpen(true);
          }}
          className={classes.actionArea}
        >
          <CardContent>
            <Typography variant="h6" color="primary" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.text}>
              {overview}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Box m={1}>
            <Button
              to="/contact"
              size="small"
              variant="contained"
              color="primary"
              style={{
                marginRight: '8px',
              }}
            >
              Contact Sales
            </Button>
            {html_content_show && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => setDialogOpen(true)}
              >
                Learn More
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" className={classes.titleDialog}>
              Course Details
            </Typography>
            <IconButton
              onClick={() => setDialogOpen(false)}
              className={classes.closeIcon}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <Typography variant="h5" color="primary" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1">
              {html_content ? (
                <div dangerouslySetInnerHTML={{ __html: html_content }} />
              ) : (
                overview
              )}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CourseCard;
