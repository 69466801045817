import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core';

import { CookieSnackbar } from '../';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  child: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

const Layout = ({ location, children, navbarPosition }) => {
  const classes = useStyles();
  const [navPos] = useState(() => (navbarPosition ? navbarPosition : 'fixed'));
  return (
    <div className={classes.root}>
      <Navbar location={location} navbarPosition={navPos} />
      {navPos === 'fixed' && <div className={classes.toolbar} />}
      <div className={classes.child}>{children}</div>
      <CookieSnackbar />
      <Footer />
    </div>
  );
};

export default Layout;
