import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
    },
    paddingBottom: theme.spacing(4),
  },
  tagline: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(36),
  },
}));

const Hero = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h1"
          align="center"
          className={classes.tagline}
        >
          {title}
        </Typography>
      </Container>
    </div>
  );
};

export default Hero;
