import React from 'react';
import { Grid, Container, makeStyles } from '@material-ui/core';

import AboutUsSVG from '../../assets/about_us_index.inline.svg';
import AboutUs2SVG from '../../assets/about_us_index2.inline.svg';
import TextCard from '../Cards/Card/TextCard';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  SVG: {
    display: 'block',
    padding: theme.spacing(1),
    margin: 'auto',
    maxHeight: '200px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '85%',
    },
    objectFit: 'contain',
  },
}));

const data = [
  {
    title: 'Developing your people',
    text:
      'Our courses enhance the capabilities of new and experienced project management professionals. We go beyond textbook learning to provide real-world context to difficult scenarios. We deliver the competencies that empower attendees to manage complex and challenging environments.',
    svg: AboutUs2SVG,
  },
  {
    title: 'Coaching for success',
    text:
      'We are passionate about helping everyone be the best they can be. Our coaching philosophy is to listen, learn and understand. Whether it’s for an individual or for a team, we help people make better decisions with confidence and integrity. We provide mechanisms and tools that allow people to manage challenging relationships and control their environment.',
    svg: AboutUsSVG,
  },
];

const AboutUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {data.map((node, index) => {
          const NodeSVG = node.svg;
          return (
            <Grid
              key={node.title}
              container
              direction={index % 2 === 1 ? 'row' : 'row-reverse'}
              spacing={2}
              alignItems="center"
              justify="space-evenly"
            >
              <Grid item xs={12} sm={6} md={7}>
                <TextCard title={node.title} text={node.text} />
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <NodeSVG className={classes.SVG} />
              </Grid>
            </Grid>
          );
        })}
      </Container>
    </div>
  );
};

export default AboutUs;
