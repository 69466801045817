import React, { useEffect, useState } from 'react';
import { Box, Typography, Fade, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: grey[100],
    width: '100%',
    height: '12vh',
    [theme.breakpoints.down('sm')]: {
      height: '18vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '25vh',
    },
    padding: theme.spacing(4),
  },
  bannerTitle: {
    fontFamily: 'Montserrat',
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 'bold',
    color: 'white',
  },
  middot: {
    visibility: 'hidden',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  bannerSubtitle: {
    fontSize: theme.typography.pxToRem(20),
    color: 'white',
  },
}));

const BannerItem = ({ content }) => {
  const classes = useStyles();
  return (
    <Box className={classes.banner}>
      <Typography variant="h5" className={classes.bannerTitle}>
        {content.title}
      </Typography>
      <Typography variant="h6" className={classes.middot}>
        &middot;
      </Typography>
      <Typography variant="h6" className={classes.bannerSubtitle}>
        {content.subtitle}
      </Typography>
    </Box>
  );
};

const data = [
  {
    title: 'Flexible',
    subtitle: 'We work in tandem with your business strategy',
  },
  {
    title: 'Digital',
    subtitle: 'We work on-line or in person seamlessly with your business',
  },
  {
    title: 'Focused',
    subtitle: 'We put your people at the centre of our approach',
  },
  {
    title: 'Efficient',
    subtitle: 'We value your time',
  },
  {
    title: 'Results',
    subtitle: 'Improving your bottom line',
  },
];

const Banner = () => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handleChange = () => {
    const increment = 1;
    const newIndex = (index + increment + data.length) % data.length;
    setFadeIn(false);
    setTimeout(() => {
      setIndex(newIndex);
      setFadeIn(true);
    }, 500);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      handleChange();
    }, 3500);
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={classes.root}>
      <Fade in={fadeIn} timeout={500} appear>
        <div>
          <BannerItem content={data[index]}></BannerItem>
        </div>
      </Fade>
    </div>
  );
};

export default Banner;
