import React from 'react';
import { Grid, Container, makeStyles, Typography } from '@material-ui/core';

import { graphql, useStaticQuery } from 'gatsby';
import { BlogCard2 } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(30),
  },
}));

const BlogGrid = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      featuredPosts: allMarkdownRemark(
        filter: {
          frontmatter: {
            show: { eq: true }
            type: { eq: "blog" }
            featured: { eq: true }
          }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "LL")
              image {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 75) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
      allPosts: allMarkdownRemark(
        filter: {
          frontmatter: {
            show: { eq: true }
            type: { eq: "blog" }
            featured: { eq: false }
          }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "LL")
              image {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 75) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {data.featuredPosts.edges.length > 0 && (
          <Typography variant="h4" color="primary" className={classes.title}>
            Featured Posts
          </Typography>
        )}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          justify="flex-start"
        >
          {data.featuredPosts.edges.map(({ node }) => (
            <Grid key={node.fields.slug} item xs={12} sm={6} md={4}>
              <BlogCard2
                title={node.frontmatter.title}
                text={node.excerpt}
                path={`/blog${node.fields.slug}`}
                image={node.frontmatter.image.childImageSharp.fluid}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        {data.allPosts.edges.length > 0 && (
          <Typography variant="h4" color="primary" className={classes.title}>
            Recent Posts
          </Typography>
        )}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          justify="flex-start"
        >
          {data.allPosts.edges.map(({ node }) => (
            <Grid key={node.fields.slug} item xs={12} sm={6} md={4} lg={3}>
              <BlogCard2
                title={node.frontmatter.title}
                text={node.excerpt}
                date={node.frontmatter.date}
                path={`/blog${node.fields.slug}`}
                image={node.frontmatter.image.childImageSharp.fluid}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default BlogGrid;
