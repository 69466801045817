import React from 'react';
import {
  Box,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import SVG2 from '../../assets/index_1.inline.svg';
import SVG1 from '../../assets/index_2.inline.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
  },
  titleAccordion: {
    fontFamily: 'Montserrat',
  },
  sideText: {
    padding: theme.spacing(1),
  },
  list: {},
  listTitle: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(1),
  },
  listTitle2: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listTitle3: {
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(2),
  },
  listBody: {
    color: grey[900],
  },
  SVG: {
    display: 'block',
    padding: theme.spacing(1),
    margin: 'auto',
    maxHeight: '250px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
      width: '85%',
    },
    objectFit: 'contain',
  },
}));

const listData = [
  {
    id: 1,
    title: 'The management of risk',
    text: [
      'Understanding the project business case and maximising returns',
      'Converting risk into a competitive advantage by controlling ambiguity',
    ],
  },
  {
    id: 2,
    title: 'The management of change',
    text: [
      'Utilising holistic evaluation and critical thinking to make the right decisions',
      'Identifying and capitalising on opportunities to enhance returns',
    ],
  },
  {
    id: 3,
    title: 'The management of stakeholders',
    text: ['Influencing and controlling others to remove obstructions'],
  },
];

// const accordionData = [
//   {
//     id: 1,
//     title: 'Duis esse aute irure amet cupidatat amet cillum occaecat',
//     text:
//       'We work with businesses to understand their philosophy, routines, processes and people. We know that businesses often have processes that are well defined yet they see their projects are not smooth running or maximising the potential returns the company.',
//   },
//   {
//     id: 2,
//     title: 'In sit occaecat Lorem ad mollit velit voluptate minim',
//     text:
//       'Projects are run by people; they have the ability to improve the project outcome. We know efficient processes are important and we rectify issues if necessary but ultimately no process can create better results without the team making the right decisions. We don’t approach your business with assumptions or with pre-defined process improvement proposals. We listen.',
//   },
//   {
//     id: 3,
//     title: 'Adipisicing exercitation id ut aliquip exercitation pariatur enim',
//     text:
//       'When we fully understand your business and your concerns we work in collaboration with you to deliver the improvements that make the maximum impact to your projects results.',
//   },
// ];

const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={7}>
            <Paper component={Box} p={2}>
              <Typography variant="h5" className={classes.title}>
                How we improve project delivery
              </Typography>
              <Box>
                <Typography variant="body1">
                  We believe that the project team are the key to achieving project
                  delivery success. Our approach is to improve the team’s decision making
                  skills and to give each individual the tools to manage and control their
                  environment. We do this by enhancing the project team’s ability in three
                  critical areas.
                </Typography>
                {listData.map((data) => (
                  <Box key={data.id}>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.listTitle2}
                    >
                      {data.title}
                    </Typography>
                    <Typography variant="body1">
                      <ul>
                        {data.text.map((t, index) => (
                          <li key={index}>{t}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <SVG1 className={classes.SVG} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          spacing={2}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={7}>
            <Box component={Paper} p={2} mt={2}>
              <Typography variant="h5" className={classes.title}>
                Working with your business
              </Typography>
              <Typography variant="body1">
                We know that businesses often have processes that are well defined yet
                they see their projects are not smooth running or maximising the potential
                returns to the company. We focus in on why this is and concentrate on
                improving the team by giving them the ability to make better decisions
                that drive improved results.
              </Typography>
              <Typography variant="h6" color="primary" className={classes.listTitle2}>
                Achieving tangible results for SMEs and Large Organisations
              </Typography>
              <Typography variant="body1">
                We know efficient processes are important and we rectify issues if
                necessary, but ultimately, no process can create better results without
                the team making the right decisions. We don’t approach your business with
                assumptions or with pre-defined process improvement proposals. We listen.
                We work in collaboration with your people and in harmony with your
                business objectives to deliver changes that have the maximum impact to
                your projects results.
              </Typography>
              <Typography variant="h6" color="primary" className={classes.listTitle3}>
                Who we serve
              </Typography>
              <Typography variant="body1">
                <Hidden xsDown>
                  <Grid container spacing={0} justify={'flex-start'} align={'flex-start'}>
                    <Grid item sm>
                      <ul>
                        <li>Project Services</li>
                        <li>Design &amp; Build Engineering</li>
                        <li>Service Sector</li>
                        <li>Built environment</li>
                        <li>Renewable Energy</li>
                      </ul>
                    </Grid>
                    <Grid item sm>
                      <ul>
                        <li>Energy Generation</li>
                        <li>Communication Systems</li>
                        <li>Oil &amp; Gas</li>
                        <li>Petrochemical</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <ul>
                    <li>Project Services</li>
                    <li>Design &amp; Build Engineering</li>
                    <li>Service Sector</li>
                    <li>Built environment</li>
                    <li>Renewable Energy</li> <li>Energy Generation</li>
                    <li>Communication Systems</li>
                    <li>Oil &amp; Gas</li>
                    <li>Petrochemical</li>
                  </ul>
                </Hidden>
              </Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={5}>
              <SVG2 className={classes.SVG} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default Banner;
