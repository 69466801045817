import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

const contactFormEndpoint = '/api/contact';

const ContactForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const recaptchaRef = useRef();
  const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return (
    <>
      {!isComplete ? (
        <Formik
          initialValues={{
            name: '',
            email: '',
            company: '',
            phone: '',
            message: '',
            recaptcha: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            email: Yup.string()
              .email('Must be a vaild email address.')
              .required('Required'),
            company: Yup.string(),
            phone: Yup.string().matches(phoneRegex, 'Phone number is not valid.'),
            message: Yup.string().required('Required'),
            recaptcha: Yup.string().required('Required'),
          })}
          onSubmit={(values) => {
            setSubmitting(true);
            axios
              .post(contactFormEndpoint, values, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                },
              })
              .then((res) => {
                setResponseMessage(res.data.message);
                setDialogOpen(true);
                setSubmitting(false && isSubmitting);
                setComplete(true);
              });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'Button Click',
              category: 'Custom Button',
              action: 'Click',
              label: 'Contact Form Submit Button',
            });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Container
                  maxWidth="md"
                  style={{
                    padding: 0,
                  }}
                >
                  <Paper
                    style={{
                      padding: '14px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="primary"
                      style={{ fontFamily: 'Montserrat', marginBottom: '14px' }}
                    >
                      Contact Us
                    </Typography>
                    <Grid spacing={2} container justify="flex-start">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Name"
                          name="name"
                          variant="outlined"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.name && touched.name && errors.name}
                          style={{
                            display: 'flex',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errors.email && touched.email}
                          label="Email"
                          name="email"
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.email && touched.email && errors.email}
                          style={{
                            display: 'flex',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Company"
                          name="company"
                          variant="outlined"
                          value={values.company}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.company && touched.company && errors.company}
                          style={{
                            display: 'flex',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errors.phone && touched.phone}
                          label="Phone"
                          name="phone"
                          variant="outlined"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.phone && touched.phone && errors.phone}
                          style={{
                            display: 'flex',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Message"
                          name="message"
                          multiline
                          variant="outlined"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.message && touched.message && errors.message}
                          style={{
                            display: 'flex',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          value={values.recaptcha}
                          sitekey={'6LfdFNEZAAAAAEU0osOVqWXay1ZGCVQ1VuoThoi_'}
                          onChange={() => {
                            values.recaptcha = recaptchaRef.current.getValue();
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      style={{
                        marginTop: '14px',
                      }}
                    >
                      Submit
                    </Button>
                  </Paper>
                </Container>
              </form>
            );
          }}
        </Formik>
      ) : (
        <Dialog
          onClose={() => {
            setDialogOpen(false);
            setComplete(false);
          }}
          aria-labelledby="dialog-title"
          open={dialogOpen}
        >
          <DialogTitle>
            <Box>
              <Typography color="primary" style={{ fontFamily: 'Montserrat' }}>
                Thanks for submitting
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box pb={2}>
              <Typography variant="body1">{responseMessage}</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ContactForm;
