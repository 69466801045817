import React from 'react';
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import ContactForm from '../ContactForm';

import SocialIcons from '../SocialIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    color: theme.palette.primary.light,
    marginTop: theme.spacing(2),
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ContactFormBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mr={2}
              ml={2}
            >
              <Typography variant="h4" align="center" className={classes.subTitle}>
                Got a Question?
              </Typography>
              <Typography variant="h5" align="center" className={classes.title}>
                Connect with us today.
              </Typography>
              <Box display="flex" mt={1}>
                <SocialIcons display="flex" justifyContent="center" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactFormBanner;
