import React, { useState, useEffect } from 'react';
import { Box, Fade, Typography, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '150px',
    [theme.breakpoints.up('md')]: {
      height: '100px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '75px',
    },
  },
  banner: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: grey[100],
    width: '100%',
    height: '150px',
    [theme.breakpoints.up('md')]: {
      height: '100px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '75px',
    },
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.primary.main,
    },
  },
  bannerTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: 'white',
  },
  middot: {
    visibility: 'hidden',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const data = [
  {
    title: 'Flexible',
    subtitle: 'We work in tandem with your business strategy',
  },
  {
    title: 'Digital',
    subtitle: 'We work on-line or in person seamlessly with your business',
  },
  {
    title: 'Focused',
    subtitle: 'We put your people at the centre of our approach',
  },
  {
    title: 'Efficient',
    subtitle: 'We value your time',
  },
];

const FadingBanner = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let scrollInterval = setTimeout(() => {
      setActiveIndex((activeIndex + 1) % data.length);
    }, 3500);
    return () => clearTimeout(scrollInterval);
  }, [activeIndex]);

  return (
    <div className={classes.root}>
      {data.map((node, index) => {
        return (
          <Fade
            key={node.title}
            timeout={{
              enter: 2000,
              exit: 2000,
            }}
            in={index === activeIndex}
            unmountOnExit
          >
            <Box className={classes.banner}>
              <Typography variant="h5" className={classes.bannerTitle}>
                {node.title}
              </Typography>
              <Typography variant="h6" className={classes.middot}>
                &middot;
              </Typography>
              <Typography variant="h6">{node.subtitle}</Typography>
            </Box>
          </Fade>
        );
      })}
    </div>
  );
};

export default FadingBanner;
