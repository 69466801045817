import React from 'react';
import Img from 'gatsby-image';
import { Container, Paper, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '55vh',
    width: '100%',
    backgroundColor: grey[200],
    borderRadius: '4px',
    overflow: 'hidden',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    fontFamily: 'Montserrat',
  },
  author: {
    fontFamily: 'Montserrat',
  },
  body: {
    paddingBottom: theme.spacing(1),
  },
}));

export default function BlogPost({ title, author, html, image }) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg" disableGutters>
        <Paper className={classes.paper}>
          <div>
            <Typography color="primary" variant="h5" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.author}>
              {author}
            </Typography>
            <div>
              <Typography variant="body1" className={classes.body}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Typography>
            </div>
          </div>
        </Paper>
        <Img
          backgroundColor={grey[800]}
          fadeIn
          durationFadeIn={2000}
          fluid={image}
          className={classes.image}
        />
      </Container>
    </>
  );
}
