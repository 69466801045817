import React from 'react';
import { Box, Paper, Typography, makeStyles, Hidden } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    position: 'relative',
  },
  cardWrapper: {
    height: '250px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textTransform: 'none',
    alignItems: 'center',
  },
  cardTitle: {
    color: 'white',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  cardText: {
    color: 'white',
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: theme.spacing(2),
    color: grey[100],
    borderColor: grey[100],
    // padding: '4px',
    // borderRadius: '4px',
    // border: `1px solid ${grey[300]}`,
  },
}));

const CardAbout = ({ title, text, image, path }) => {
  const classes = useStyles();
  return (
    <Paper elevation={8} className={classes.paperWrapper}>
      <Button
        to={path}
        fullWidth
        style={{
          background: `radial-gradient( rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.35) ), url(${image.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Box className={classes.cardWrapper}>
          <Typography variant="h5" align="center" className={classes.cardTitle}>
            {title}
          </Typography>
          <Typography variant="subtitle1" align="center" className={classes.cardText}>
            {text}
          </Typography>
          <Hidden mdUp>
            <Button variant="outlined" size="small" to={path} className={classes.button}>
              Tap to learn more
            </Button>
          </Hidden>
        </Box>
      </Button>
    </Paper>
  );
};

export default CardAbout;
